<template>
  <div class="header">
    <div class="topbg">
      <div class="top flex-between">
        <div class="flex-around left">
          <p v-if="!islogin">
            <span>您好，</span><span class="bold" @click="showlogin">请登录</span>
          </p>
          <p class="flex-around" v-if="!islogin">
            <span>没有账号？</span>
            <span class="bold hand" @click="dia2 = true">马上注册</span>
            <img src="../assets/public/newpeople.png" alt="" />
          </p>
          <p v-if="islogin">
            <span>您好，</span>
            <span class="bold">{{logininfo.username}}</span>，
            <span @click="out">退出登录</span>
          </p>
          <p class="flex-around" v-if="islogin">
            <span @click="tourl('/user/my')">我的趣游</span>
            <span style="margin-left:20px" @click="tourl('/user/ordermsg?id=1')">消息</span>
            <!-- <span style="margin-left:20px;color: #ff4c12;"  @click="tourl('/user/buycard')">体验卡购买</span> -->
            
          </p>
        </div>
        <div class="flex-around right">
          <!-- <img src="../assets/public/p1.png" alt="" />
          <span class="bold" @click="tourl('/quyou/invitation')">邀请有礼</span> -->
          <div class="img1">
            <img src="../assets/public/p2.png" />
            <img src="../assets/public/ewm1.jpg" class="ewm1">
          </div>
          <div class="img2">
            <img src="../assets/public/p3.png" />
            <img src="../assets/public/gzh.jpg" class="ewm2">
          </div>
          
          
        </div>
      </div>
    </div>

    <div class="search flex-around">
      <img src="../assets/public/logo.png" alt="趣游网logo" class="left" />
      <div class="center flex-between">
        <div class="city" @click="getcitylist">
          <p>{{city | wuellipsis(3)}}</p>
          <img src="../assets/public/sjx.png" alt="" />
         
        <div class="citylist" v-if="showcity">
          <ul>
            <li v-for="(item,i) in citylist" :key="i" @click="city=item">{{item}}</li>
          </ul>
        </div>
        </div>
        <input
          type="text"
          placeholder="请输入目的地/出发城市或关键词"
          v-model="keyword"
        />
        <div class="but" @click="search">搜索</div>
      </div>
      <div class="right flex-around">
        <img src="../assets/public/phone.png" alt="" />
        <div class="time">
          <p class="t1">{{timePhone}}</p>
          <p class="t2" style="width:220px">{{timekf}}</p>
        </div>
      </div>
    </div>

    <div class="nav">
      <ul>
        <li>
          <router-link to="/index">首页</router-link>
        </li>
        <li>
          <router-link to="/cityTourism">城市微游</router-link>
        </li>
        <li>
          <router-link to="/peripheralActivities">周边活动</router-link>
        </li>
        <li>
          <router-link to="/domesticTravels">国内长途</router-link>
        </li>
        <li><router-link to="/travelAbroad">境外旅行</router-link></li>
       
        <li><router-link to="/quOutdoors">趣·户外</router-link></li>
        <li><router-link to="/quTravel">趣·旅行</router-link></li>
         <li><router-link to="/active">趣·跟团</router-link></li>
          <li>
          <a href="#" @click="tck">团队定制</a>
        </li>
        <!-- <li><router-link to="/trivialShot">游记旅拍</router-link></li> -->
       

        <li class="img">
          <img src="../assets/public/xcx.png" alt="趣游网小程序" class="link"/>
          <img src="../assets/public/ewm1.jpg" class="qyewm">
        </li>

        <!-- <router-link to="/register" tag="span">register</router-link> -->
      </ul>
    </div>

    <el-dialog :visible.sync="dia" :show-close="false">
      <div class="login">
        <div v-if="!isewm">
        <!-- 密码登录 -->
        <div class="form">
          <div class="title flex-between">
            <h3>验证码登录</h3>
            <p  @click='dia2=true' class="hand" style="cursor:pointer;">免费注册</p>
          </div>
        
         <div class="inp">
            <input type="text" placeholder="请输入手机号"  :maxlength="11"  v-model="form2.phone"/>
          
          </div>

          <div class="inp">
            <input  type="text"   placeholder="请输入手机验证码"  v-model="form2.phone_code" />
             <span class="toyzm" @click="toyzm(1)">{{ time }}</span>
          </div>
          <div class="inp">
            <input type="text" placeholder="请输入图型验证码" class="yzm" v-model="yzm" />
            <div class="yzm-code" @click="makeCode(0, 4)">
              <yzm :identifyCode="identifyCode" />
            </div>
          </div>
          <div class="but" @click="tologin" >
            登录
          </div>
         
        </div>
       
        </div>
        <div class="wxewm" v-if="isewm">
          <h2>微信扫一扫</h2>
          <wxlogin :appid="appid" :scope="scope" :redirect_uri="encodeURIComponent(redirect_uri)"
            href="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKCiBkaXNwbGF5OiBub25lOwoKfQoKLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CgogZGlzcGxheTogbm9uZTsKCn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewoKIGJvcmRlcjogbm9uZTsKCiB3aWR0aDogMjgwcHg7CgogaGVpZ2h0OiAyODBweDsKCn0KCi5pbXBvd2VyQm94IC5zdGF0dXN7CgogZGlzcGxheTogbm9uZQoKfQ=="
            ></wxlogin>
      </div>
        <img src="../assets/public/rightimg.png" height="450px" />
        <img
          src="../assets/public/close2.png"
          class="close"
          @click="dia = false"
        />
        <div class="ewm" 
       
        @click="toWXlogin">
          <img src="../assets/public/ewm1.png" />
          <p>微信扫码登录</p>
        </div>
        
      </div>
      
    </el-dialog>
	<!-- 注册 -->
    <el-dialog :visible.sync="dia2" :show-close="false">
      <div class="login">
        <div class="form">
          <div class="title flex-between">
            <h3>免费注册</h3>
            <!-- <img src="" alt="" /> -->
          </div>
          <!-- <p class="tip">请填写下列信息</p> -->
          <div class="inp">
            <input
              type="text"
              placeholder="请输入用户名称"
              v-model="form.account"
            />
          </div>
         
         
          <div class="sex">
            <div class="flex-around" @click="form.sex =1">
              <img src="../assets/public/check1.png" v-if="form.sex == 1" />
              <img src="../assets/public/check2.png" v-if="form.sex == 0" />
              <p>男</p>
            </div>

            <div class="flex-around" @click="form.sex = 0">
              <img src="../assets/public/check1.png" v-if="form.sex == 0" />
              <img src="../assets/public/check2.png" v-if="form.sex == 1" />
              <p>女</p>
            </div>
          </div>
          <div class="inp">
            <input
              type="text"
              placeholder="请输入手机号"
              :maxlength="11"
              v-model="form.phone"/>
            
          </div>

          <div class="inp">
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="form.phone_code"
              
            />
           <span class="toyzm" @click="toyzm(2)">{{ time }}</span>
          </div>
          <div class="tip">
            <img
              src="../assets/public/check1.png"
              v-if="read"
              @click="read = false"
            />
            <img
              src="../assets/public/check2.png"
              v-if="!read"
              @click="read = true"
            />
            <p>
              我已浏览并接受<router-link to="/quyou/useProtocol">《使用协议》</router-link>
              <router-link to="/quyou/privacyClause">《隐私条款》</router-link>
            </p>
          </div>
          <div class="but" @click="upmsg">
            提交
          </div>
        </div>
        <img src="../assets/public/rightimg.png" alt="" />
        <img
          src="../assets/public/close2.png"
          class="close"
          @click="dia2 = false"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getyzmAPI, registerAPI,loginpcAPI,infoUserAPI ,userAuthAPI, logoutAPI} from "../api/login";
import { cityListAPI} from "../api/index";
import yzm from "../components/yzm";
import wxlogin from 'vue-wxlogin';
import {baseURL,url} from '../http/base'
export default {
  components: {
    yzm,wxlogin
  },
  computed:{
      islogin(){
        var flag=localStorage.getItem('token')
        if (flag==null || flag=="") {
          return false
        } else {
           return true
        }
      },
      timePhone(){
        var week=(new Date()).getDay()   
         if(week==0 || week==6){
            return "18817885198"
         }

        var hour=(new Date()).getHours()        
        if (hour>=9 && hour<18) {
          return "400 888 9318"
        } else {
          return "18817885198"
        }
      },
      timekf(){
         var week=(new Date()).getDay()   
         if(week==0 || week==6){
            return "客服时间：18:00~09:00（非工作时段）"
         }
         var hour=(new Date()).getHours()
        if (hour>=9 && hour<18) {
          return "客服时间：09:00~18:00（工作日）"
        } else {
          return "客服时间：18:00~09:00（非工作时段）"
        }
      }
  },
  watch:{
    $route(to,from){
      this.dia2=false
      let code=this.$route.query.code
    if(code){
      console.log(code)
      userAuthAPI({code}).then(res=>{
        localStorage.setItem("aa",JSON.stringify(res.data))
        if (res.data.is_bind==0) {
          localStorage.setItem("wxInfo",JSON.stringify(res.data))
          this.$router.push({ path: '/updateInfo'})
           this.dia=false
        } else {
          	localStorage.setItem("token", res.token)     
            this.dia=false
            this.$router.push({ path: '/user/my',query:{login:"success"}})
        }        
      })
    }
    }
  },
  mounted(){
    // console.log(localStorage.getItem('token'))
    
    var flag=localStorage.getItem('token')
        if (flag!=null && this.logininfo.username=="") {
           infoUserAPI().then(res=>{
            this.logininfo.username=res.user.account
            localStorage.setItem("QY_USER",res.user.account)
          })
        } 
  },
 
  data() {
    return {
      appid:"wx6b13064cddab25fc",
      scope:"snsapi_login",
      redirect_uri:url+"#/index",
      isewm:false,
      logininfo:{
        img:"",
        username:""
      },
     is_login:true,
      identifyCodes: "1234567890",
      identifyCode: "",
      keyword: "",
      dia: false,
      dia2: false,
      form: {
        sex: 1,
        phone: "",
       
        account: "",
        phone_code: ""
      },
	   form2: {
        phone:"",
		   
      },
      
	    yzm:"",
      password: "",
      read: false,
      time: "发送验证码",
      citylist:[],
      city:"上海",
      showcity:false
    };
  },

  methods: {
    tck(){
      this.$message.success("团队定制请拨打：400-888-9318")
    },
    toWXlogin(){
      this.isewm=!this.isewm
    },
   
    out(){
      logoutAPI({
        token: localStorage.getItem("token")
      }).then(res => {
        localStorage.removeItem("token")
        this.$router.push({ path: "/" })
        setTimeout(()=>{
          window.location.reload()
        },20)
      })
    },
    getcitylist(){
      cityListAPI().then(res=>{
        this.citylist=res.cateList
        this.showcity=!this.showcity
      })
    },
	  showlogin(){
		    this.identifyCode = "";
    		this.makeCode(this.identifyCodes, 4);
			  this.dia = true
	  },
    search() {
      var key = {
        content: this.keyword,
        station:this.city
      };
      if (this.keyword!="") {
         localStorage.setItem("keyword",this.keyword)
        this.$router.push({ path: "/searchlist", query: key });
        this.$emit('parentshow',this.keyword,this.city)
      } 
     
    },
    tourl(url){
      this.$router.push({ path: url })
      console.log(url)
    },
    upmsg() {
      
        if (this.read) {
          registerAPI(this.form).then(res => {
          
            this.$store.commit("setToken", res.token);
			      this.$message({
              message: "注册成功",
              type: "success"
            });
            this.dia2=false
           
            	localStorage.setItem("token", res.token);
             this.$router.push({ path: '/user/my',query:{login:"success"}})
          });
        } else {
          this.$message("请勾选确认阅读");
        }
      
    },
    toyzm(n) {
        if (n==2) {

           if (this.form.phone.length != 11) {
              this.$message({ message: "请输入正确的手机号", type: "error"  });
              return
            }
        } else {
          if (this.form2.phone.length != 11) {
              this.$message({ message: "请输入正确的手机号", type: "error"  });
              return
            }
        }
     
        if (this.time == "发送验证码") {
          var that = this;
          that.time = 60;
          if (that.time > 0) {
            var interval = setInterval(function() {
              that.time--;
              if (that.time == 0) {
                clearInterval(interval);
                that.time = "发送验证码";
              }
            }, 1000);
          }
          var phone=n==1 ? this.form2.phone : this.form.phone
          getyzmAPI({ phone }).then(res => {
            this.$message({
              message: "验证码短信已发送",
              type: "success"
            });
          });
        } else {
          this.$message({
            message: "请耐心等待",
            type: "warning"
          });
        }
      
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      this.identifyCode = "";
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
      console.log(this.identifyCode);
    },
	tologin(){
		if(this.yzm==this.identifyCode){
			loginpcAPI(this.form2).then(res=>{
			localStorage.setItem("token", res.token);
      
        this.$message({ message: "登录成功",  type: "success" });
        this.dia=false
        this.$router.push({ path: '/user/my',query:{login:"success"}})
           
			})
		}else{
			this.$message({ message: "图形验证码不一致", type: "warning"})
		}
		
	}
  }
};
</script>

<style lang="scss">
.header {
  .flex-around{
   span{
      cursor: pointer;
   }
  }
		.yzm-code{
	height: 40px;
	width: 110px;
	float: right;
	}
  .el-dialog {
    border-radius: 10px;
    padding: 0;
    width: 730px;
    height: 452px;
    .el-dialog__header {
      display: none;
      padding: 0;
      margin: 0;
    }
    .el-dialog__body {
      // background-color: red;
      border-radius: 10px;
      padding: 0;
      margin: 0;
      position: relative;
      .login {
        display: flex;
        justify-content: space-between;
        .form {
          width: 420px;
          padding: 35px 50px;
          height: 450px;
          .title {
            h3 {
              font-size: 20px;
              font-weight: 500;
              color: #333333;
              line-height: 28px;
            }
            p {
              font-size: 14px;

              color: #ff7c00;
              line-height: 20px;
            }
          }
          .inp {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            position: relative;

            input {
              width: 321px;
              height: 40px;
              background: #f6f7f9;
              border-radius: 4px;
              text-indent: 15px;
            }
            .yzm {
              width: 170px;
            }
            .toyzm {
              color: #ff7c00;
              position: absolute;
              right: 20px;
              top: 0;
              line-height: 40px;
              cursor: pointer;
            }
            .pass {
              width: 150px;
            }
          }
          .sex {
            display: flex;
            margin-top: 10px;
            margin-bottom: 5px;
            div {
              margin-right: 20px;
              img {
                margin-right: 10px;
              }
            }
          }
          .tip {
            display: flex;
            align-items: center;
            font-size: 13px;
            margin-top: 10px;
            color: #999999;
            line-height: 20px;
            p {
              color: #999999;
            }
            img {
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
            span {
              color: #ff7c00;
              font-size: 13px;
            }
            a{
              color: #ff7c00;
            }
          }
          .but {
            width: 321px;
            height: 45px;
            opacity: 1;
            background: #ff7c00;
            border-radius: 4px;
            text-align: center;
            margin-top: 20px;
            line-height: 45px;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
          }
        }
        .wxewm{
           padding-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          // align-items: center;
          flex-wrap: wrap;
          h2{
            width: 100%;
            text-align: center;
           
          }

        }
      }
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      .ewm {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        font-size: 14px;

        color: #333333;
        line-height: 20px;
      }
    }
  }

  .topbg {
    width: 100%;
    min-width: 1200px;
    background: #fff9ef;
    .top {
      height: 35px;
      width: 1200px;
      // background-color: red;
      margin: 0 auto;
      font-size: 13px;
      line-height: 18px;
      .bold {
        font-weight: bold;
        color: #6d380c;
        cursor: pointer;
      }

      p {
        margin-left: 5px;
        margin-right: 45px;
        img {
          height: 18px;
          margin-left: 5px;
        }
      }
      .right {
        position: relative;
        
        img {
          width: 20px;
          height: 20px;
          margin: 0 6px;
        }
        .ewm1{
          width: 100px;
          height: 100px;
          border-radius: 0;
          position: absolute;
          top: 25px;
          right: 0px;
          display: none;
        }
        .img1:hover .ewm1{
          display: block;
        }
       .ewm2{
          width: 100px;
          height: 100px;
          border-radius: 0;
          position: absolute;
          top: 25px;
          right: 0px;
          display: none;
        }
        .img2:hover .ewm2{
          display: block;
        }
        span {
          margin-right: 30px;
        }
      }
    }
  }

  .search {
    width: 1000px;
    height: 90px;
    margin: 0 auto;
    .left {
      height: 35px;
    }
    .center {
      width: 600px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #ff7c00;
      border-radius: 27px;
      .city {
        width: 100px;
        padding-left: 20px;
        // background-color: red;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: bold;
       
        img {
          margin-left: 10px;
          height: 7px;
          width: 11px;
           cursor: pointer;
        }
        >p{
          cursor: pointer;
           font-size: 12px;
        }
        position: relative;
        .citylist{
          position: absolute;
          z-index: 10;
          top: 40px;
          left: 15px;
          ul{
            padding:0 10px;
            background-color: #fff;
            border:1px solid rgb(240, 240, 240);
            li{
              font-size: 16px;
              font-weight: normal;
               cursor: pointer;
            }
            li:hover{
              color: #ff7c00;
            }
          }
        }
        // .el-dropdown-link {
        //   cursor: pointer;
        
        // }
        // .el-icon-arrow-down {
        //   font-size: 12px;
        // }
      }
      input {
        flex: 1;
        height: 38px;
        font-size: 15px;
        line-height: 38px;
      }
      .but {
        width: 110px;
        height: 40px;
        background: #ff7c00;
        border-radius: 0px 27px 27px 0px;

        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 40px;
      }
    }
    .right {
      width: 170px;

      img {
        width: 21px;
        height: 21px;
      }
      .t1 {
        font-size: 17px;
        font-weight: 500;
      }
      .t2 {
        font-size: 11px;
        color: #666666;
        line-height: 17px;
      }
    }
  }

  .nav {
    width: 1200px;
    margin: 0 auto;
    height: 30px;
    ul {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      font-weight: 500;
      li {
        margin-right: 50px;
        a {
          font-weight: 500;
        }
        // .active{
        // 	color: #FF7C00;
        // 	font-weight: 600;
        // }
        .router-link-active,
        .myactive {
          color: #ff7c00;
          font-weight: 600;
        }
      }
      .img {
        margin-right: 0;
        width: 140px;
        display: flex;
        align-items: center;
        position: relative;
        img {
          width: 100%;
        }
        .qyewm{
          position: absolute;
          top: 30px;
          left: 0;
          z-index: 999;
          display:none;
        }
        
      }
      .img:hover .qyewm{
          display: block;
        }
    }
  }
}
</style>
