<template>
	<div class="right">
		<div class="chose">
			<div @click="tourl('/user/order')">
				<img src="../assets/public/right1.png" alt="">
				<p>我<br/>的<br/>订<br/>单<br/></p>
			</div>
			<div  class="gzh">
				<img src="../assets/public/right2.png" alt="">
				<p>联<br/>系<br/>客<br/>服<br/></p>
				<div class="ewm2 tel">
					400-888-9318
				</div>
				<!-- <div class="ewm2 tel">
					<a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2213127617&site=qq&menu=yes">
					<img border="0" src="http://wpa.qq.com/pa?p=2:2213127617:53" alt="点击这里给我发消息" title="点击这里给我发消息"/>
					</a>
				</div> -->
			</div>
			<!-- <div @click="tourl('/trivialShot')">
				<img src="../assets/public/right3.png" alt="">
				<p>游<br/>记<br/>旅<br/> 拍<br/></p>				
			</div> -->
			<div class="gzh">
				<img src="../assets/public/right4.png" alt="">
				<p>公<br/>众<br/>号<br/></p>
				 <img src="../assets/public/gzh.jpg" class="ewm2">
			</div>

		</div>
		<div class="totop" @click="backTop">
			<img src="../assets/public/right5.png" alt="">
			<p>返<br/>回<br/>顶<br/>部<br/></p>
		</div>
	</div>
</template>

<script>
export default {
  name: 'index',

  data () {
    return {
      scrollTop: 0
    }
  },
  methods: {
    backTop () {
		      const that = this
		      const timer = setInterval(() => {
		        const ispeed = Math.floor(-that.scrollTop / 5)
		        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
		        if (that.scrollTop === 0) {
		          clearInterval(timer)
		        }
		      }, 160)
		  },
	tourl (url) {
      this.$router.push({ path: url })
    }
  }
}
</script>

<style lang="scss" scoped>
	.right{
		z-index: 999;
		position: fixed;
		top: 0;
		right: 0;
		width: 35px;
		height: 100%;
		background: rgba(0,0,0,0.70);
		p{
			width: 35px;
			text-align: center;
			color: rgba(255,255,255,0.70);
			font-size: 13px;
			word-break:break-all;
			word-warp:warp;
		}
		img{
			width:21px;
			display: block;
			margin: 5px auto;

		}
		.chose{
			margin-top: 50px;
			border-top: 1px solid rgba(255,255,255,0.25);
			>div{
				border-bottom: 1px solid rgba(255,255,255,0.25);
				padding: 10px 0;
				cursor: pointer;
			}

		}
		.totop{
			margin-top:70px;
			cursor: pointer;
		}

	}
	.gzh{
		position: relative;
		.ewm2{
			width: 100px;
			height: 100px;
			border-radius: 0;
			position: absolute;
			top:0px;
			right: 35px;
			display: none;
		}
		.tel{
			top: 40px;
			text-align: center;
			width:160px;
			height: 40px;
			line-height: 40px;
			border: 1px solid #ff7c00;
			border-right: none;
			color: #ff7c00;
			font-size: 18px;
			// font-weight: bold;
			background-color: #fff;
		}
	}
	
	.gzh:hover .ewm2{
		display: block;
	}
		
</style>
